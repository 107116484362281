<template>
  <div>
    <h3 class="font-weight-semi-bold">{{ $t('onboarding.dataOverview.desc') }}</h3>

    <v-card
      color="element"
      class="mx-auto my-12"
      max-width="500"
    >
        <v-list
          color="element"
        >
          <template v-for="section in sections">

            <!-- MAIN KEYS -->
            <v-card-title
              :key="section"
              v-text="$t(`onboarding.dataOverview.subTitles.${section}`)"
            ></v-card-title>
            <!-- MAIN KEYS -->

            <v-divider class="mx-4" :key="`divider${section}`"></v-divider>

            <v-card-text :key="`card-text${section}`">
              <v-list-item
                v-for="(item, index) in cleanSection(section)"
                :key="`${item}${index}`"
              >
                <v-list-item-content>

                  <!-- KEYS -->
                  <v-list-item-subtitle
                    v-if="section !== 'financialData'"
                    v-html="$t(`onboarding.${section}.fields.${index}`)">
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else
                    v-html="$t(`onboarding.${i18nSpecialField(index)}.fields.${index}`)">
                  </v-list-item-subtitle>
                  <!-- KEYS -->

                  <!-- VALUES -->
                  <v-list-item-title
                    v-if="nonTextInputFields.includes(index)"
                    v-html="nonTextInputFieldTransformer(index, item)">
                  </v-list-item-title>
                  <v-list-item-title
                    v-else
                    v-html="item">
                  </v-list-item-title>
                  <!-- VALUES -->

                </v-list-item-content>

                <!-- EDIT ACTION BUTTON -->
                <v-list-item-action v-if="index !== 'email'">
                  <v-btn
                    icon
                    @click="edit(section, index)"
                  >
                    <v-icon
                      color="primary"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
                <!-- EDIT ACTION BUTTON -->

              </v-list-item>
            </v-card-text>

          </template>
        </v-list>

    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { include, exclude, excludeEmpty } from '@/utils/json';

export default {
  name: 'Overview',
  data: () => ({
    specialFieldsMap: {
      refBankAccOwner: 'BankAccountInformation',
      refBankIban: 'BankAccountInformation',
      refBankBic: 'BankAccountInformation',
      countryTaxation: 'TaxInformation',
      taxNo: 'TaxInformation',
      sourceOfWealth: 'SourceOfWealthInformation',
    },
    nonTextInputFields: [
      'gender',
      'countryResidence',
      'countryBirth',
      'countryPrimCitizen',
      'countryTaxation',
      'sourceOfWealth',
    ],
  }),
  computed: {
    ...mapGetters('onboarding', [
      'genders',
      'sourcesOfWealth',
      'allCountries',
    ]),
    ...mapGetters('user', [
      'user',
    ]),
    cleanUser() {
      return include([
        'personalData',
        'contactData',
        'identificationData',
        'financialData'], this.user);
    },
    sections() {
      return Object.keys(this.cleanUser);
    },
  },
  methods: {
    i18nSpecialField(field) {
      return this.specialFieldsMap[field].decapitalize();
    },
    edit(section, field) {
      const targetedStep = (section !== 'financialData')
        ? section.capitalize()
        : this.specialFieldsMap[field];
      this.$store.dispatch('onboarding/editMode', targetedStep);
      this.$router.push({
        name: 'Onboarding.Personal',
        params: {
          step: targetedStep,
        },
      });
    },
    cleanSection(section) {
      let sectionObj;
      if (section === 'identificationData') {
        sectionObj = exclude(
          ['isUsCitizen'], this.cleanUser[section],
        );
      } else if (section === 'contactData') {
        sectionObj = exclude(
          ['phone', 'mailbox'], this.cleanUser[section],
        );
      } else {
        sectionObj = this.cleanUser[section];
      }

      const data = excludeEmpty(sectionObj);

      // Order data points
      if (section === 'identificationData') {
        const order = [
          'birthDay',
          'birthPlace',
          'countryBirth',
          'countryPrimCitizen',
        ];
        const orderedSection = {};
        order.forEach((e) => {
          orderedSection[e] = data[e];
        });
        return orderedSection;
      }

      if (section === 'financialData') {
        const order = [
          'countryTaxation',
          'taxNo',
          'refBankAccOwner',
          'refBankIban',
          'refBankBic',
          'sourceOfWealth',
        ];
        const orderedSection = {};
        order.forEach((e) => {
          orderedSection[e] = data[e];
        });
        return orderedSection;
      }

      return data;
    },
    nonTextInputFieldTransformer(keyFunc, value) {
      return this[keyFunc](value);
    },
    // Functions for nonTextInputFieldTransformer()
    // Must be the same as this.nonTextInputFields
    gender(v) { return this.genders.find((e) => e.value === v).text; },
    countryResidence(v) { return this.allCountries.find((e) => e.alpha3 === v).name; },
    countryBirth(v) { return this.allCountries.find((e) => e.alpha3 === v).name; },
    countryPrimCitizen(v) { return this.allCountries.find((e) => e.alpha3 === v).name; },
    countryTaxation(v) { return this.allCountries.find((e) => e.alpha3 === v).name; },
    sourceOfWealth(v) { return this.sourcesOfWealth.find((e) => e.value === v).text; },
  },
};

</script>

<style scoped>
.font-weight-semi-bold {
  font-weight: 600;
}
</style>
