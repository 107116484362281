// keep the keys that are provided
const include = (keys, obj) => (
  keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {})
);

// remove all keys except the ones provided
const exclude = (keys, obj) => {
  const keysToKeep = Object.keys(obj).filter((elt) => !keys.includes(elt));
  return include(keysToKeep, obj);
};

// remove all keys with null
const excludeEmpty = (obj) => (
  Object.keys(obj)
    .filter((k) => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {})
);

module.exports = {
  include,
  exclude,
  excludeEmpty,
};
